<script setup lang="ts">
import OtpForm from "@/Components/Forms/OtpForm.vue";
import { ref } from "vue";
import LoginForm from "@/Components/Forms/LoginForm.vue";
import RegisterForm from "@/Components/Forms/RegisterForm.vue";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const toast = useToast();

const emit = defineEmits(["logged"]);

defineProps({
    headerText: {
        type: String,
        default: "",
    },
});

const displayOtpForm = ref(true);
const displayRegisterForm = ref(false);

const identifier = ref("");
const channel = ref("");

const registerComponent = ref();
const loginComponent = ref();

const handleOtpFormSubmitted = (data) => {
    displayOtpForm.value = false;
    identifier.value = data.identifier;
    channel.value = data.channel;

    if (data.code) {
        loginComponent.value.fillCode(data.code);
    }
};

const handleIdentifierNotFound = (data) => {
    handleOtpFormSubmitted(data);

    displayRegisterForm.value = true;
    registerComponent.value.fillFormFromLoginAttempt(data);
};

const handleRegisterFormSubmitted = (data) => {
    identifier.value = data[channel.value];
    displayRegisterForm.value = false;

    if (data.code) {
        loginComponent.value.fillCode(data.code);
    }
};

const handleCancel = () => {
    displayOtpForm.value = true;
    displayRegisterForm.value = false;
};

const resetAndClose = () => {
    toast.success(t("toast.logged"));
    displayOtpForm.value = true;
    displayRegisterForm.value = false;

    emit("logged");
};
</script>

<template>
    <div class="register__header">
        <img
            src="../../../images/icons/jstr_logo.svg"
            class="register__header--logo"
            width="24"
            height="24"
            alt="close menu"
        />
        <p class="register__header--title">Bienvenue sur Joinsteer</p>
        <p v-if="headerText" class="register__header--subtitle">
            {{ headerText }}
        </p>
    </div>

    <div v-if="displayOtpForm" class="register__form first-part">
        <OtpForm
            @otpFormSubmitted="handleOtpFormSubmitted"
            @identifierNotFound="handleIdentifierNotFound"
        />
    </div>

    <div class="register__form first-part" :hidden="!displayRegisterForm">
        <RegisterForm
            ref="registerComponent"
            @registered="handleRegisterFormSubmitted"
            @cancelled="handleCancel"
            :channel="channel"
        />
    </div>

    <div
        :hidden="displayOtpForm || displayRegisterForm"
        class="register__form second-part"
    >
        <LoginForm
            ref="loginComponent"
            :identifier="identifier"
            :channel="channel"
            @logged="resetAndClose"
            @cancelled="handleCancel"
        />
    </div>
</template>
