/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import env from "./env";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/browser";
import "./storeUtm";

window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Sentry.init({
    dsn: env.VITE_SENTRY_DSN_PUBLIC,
    environment: env.VITE_SENTRY_ENVIRONMENT,

    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [new BrowserTracing()],
    sampleRate: parseFloat(env.VITE_SENTRY_SAMPLE_RATE || "0.1"),

    // To set a uniform sample rate
    tracesSampleRate: parseFloat(env.VITE_SENTRY_TRACES_SAMPLE_RATE || "1"),

    enabled: env.VITE_SENTRY_ENABLED != "false",
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
