<script setup>
import TextInput from "@/Components/Forms/Input/TextInput.vue";
import Button from "@/Components/Buttons/Button.vue";
import { ref } from "vue";
import { useForm } from "@inertiajs/vue3";

const emit = defineEmits(["cancelled", "logged"]);

const isCodeResend = ref(false);

const props = defineProps({
    identifier: {
        type: String,
        default: "",
    },
    channel: {
        type: String,
        default: "",
    },
});

const form = useForm({
    code: "",
});

const fillCode = (code) => {
    form.code = code;
};

defineExpose({ fillCode });

const submitOtpForm = () => {
    form.transform(() => ({
        channel: props.channel,
        identifier: props.identifier,
    })).post(route("auth.otp"), {
        onSuccess: () => {
            isCodeResend.value = true;
        },
    });
};

const submitLoginForm = () => {
    form.clearErrors();
    form.transform((data) => ({
        ...data,
        channel: props.channel,
        identifier: props.identifier,
    })).post(route("auth.login"), {
        onSuccess: () => {
            form.reset();
            emit("logged");
        },
    });
};

const cancel = () => {
    form.reset();
    emit("cancelled");
};
</script>

<template>
    <form @submit.prevent="submitLoginForm">
        <TextInput
            id="security_code"
            v-model="form.code"
            type="text"
            :pattern="'[0-9]{6}'"
            :label="
                $t(
                    props.channel === 'phone'
                        ? 'messages.fillWithCodeSended'
                        : 'messages.fillWithCodeSendedV2',
                    { identifier: props.identifier },
                )
            "
            required
            :placeholder="$t('messages.securityCode').toUpperCase()"
            :message="form.errors.code"
        />
        <p v-if="isCodeResend" class="register__link">
            {{ $t("messages.newCodeSended") }}
        </p>
        <p v-else class="register__link">
            {{ $t("messages.doNotReceiveCode") }}
            <span class="register__link--action" @click="submitOtpForm">
                {{ $t("actions.sendAgain") }}
            </span>
        </p>

        <div class="register__action">
            <Button
                variante="primary"
                type="submit"
                :disabled="form.code === '' || form.processing"
                >{{ $t("actions.signIn") }}</Button
            >

            <Button variante="tertiary" type="button" @click="cancel">
                {{ $t("actions.cancel") }}
            </Button>
        </div>
    </form>
</template>
