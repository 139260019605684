<script setup lang="ts">
import { Link, usePage } from "@inertiajs/vue3";
import Modal from "@/Modal/Modal.vue";
import { ref } from "vue";
import LoginCard from "@/Components/Cards/LoginCard.vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["close-menu"]);
const page = usePage();
const openingLoginModal = ref(false);
const { t } = useI18n();

const socialLogoUrl = {};
page.props.socialLinks.forEach((link) => {
    socialLogoUrl[link.icon] = new URL(
        `../../images/icons/${link.icon}.svg`,
        import.meta.url,
    );
});

const openLoginModal = (val: boolean) => {
    openingLoginModal.value = val;

    if (val) {
        emit("close-menu");
    }
};

defineProps<{ open: boolean }>();
</script>

<template>
    <nav class="app-menu" :class="{ 'app-menu--open': open }">
        <button @click="$emit('close-menu')" class="app-menu__close-button">
            <img
                src="../../images/icons/close.svg"
                class="header__button-img"
                width="24"
                height="24"
                alt="close menu"
            />
        </button>

        <div class="app-menu__wrapper">
            <div class="app-menu__section">
                <h2 class="app-menu__title">{{ t("menu.yourAccount") }}</h2>

                <Link :href="route('vehicles.index')" class="app-menu__link">{{
                    t("menu.marketplace")
                }}</Link>

                <template v-if="$page.props.auth.user">
                    <Link
                        :href="route('nova.pages.home')"
                        class="app-menu__link"
                        v-if="$page.props.auth.user.has_nova_access"
                        >{{ t("menu.admin") }}</Link
                    >
                    <Link :href="route('user.edit')" class="app-menu__link">{{
                        t("menu.myAccount")
                    }}</Link>
                    <Link
                        :href="route('savedSearchs.index')"
                        class="app-menu__link"
                        >{{ t("menu.mySavedSearches") }}</Link
                    >
                    <Link
                        :href="route('user.financingRequests')"
                        class="app-menu__link"
                        >{{ t("menu.mySimulations") }}</Link
                    >
                </template>

                <Link :href="route('aboutUs')" class="app-menu__link">
                    {{ t("menu.aboutUs") }}
                </Link>

                <a href="https://faq.joinsteer.com/fr/" class="app-menu__link">
                    {{ t("menu.faq") }}
                </a>

                <Link
                    :href="route('auth.logout')"
                    as="button"
                    method="post"
                    class="app-menu__link"
                    v-if="$page.props.auth.user"
                    >{{ t("menu.logout") }}</Link
                >

                <button
                    v-else
                    class="app-menu__link"
                    @click="openLoginModal(true)"
                >
                    {{ t("menu.login") }}
                </button>

                <Modal :show="openingLoginModal" @close="openLoginModal(false)">
                    <template #content>
                        <LoginCard @logged="openLoginModal(false)" />
                    </template>
                </Modal>
            </div>

            <div class="app-menu__section app-menu__section--make">
                <h2 class="app-menu__title">{{ t("menu.makes") }}</h2>

                <div class="list">
                    <Link
                        :href="route('vehicleMake.show', { vehicleMake })"
                        class="app-menu__link"
                        :class="{
                            actif: route('vehicleMake.show', {
                                vehicleMake,
                            }).includes($page.url),
                        }"
                        v-for="vehicleMake in $page.props.footerLink"
                        :key="`menu-link--make-${vehicleMake.id}`"
                    >
                        {{ vehicleMake.designation }}
                    </Link>
                </div>
            </div>

            <div class="app-menu__section">
                <h2 class="app-menu__title">{{ t("menu.socialNetworks") }}</h2>
                <a
                    :href="link.link"
                    target="_blank"
                    v-for="link in $page.props.socialLinks"
                    :key="`menu-link-${link.icon}`"
                    rel="noreferrer noopener"
                    class="app-menu__social-link"
                >
                    <img
                        :src="socialLogoUrl[link.icon]"
                        width="24"
                        height="24"
                        :alt="link.alt"
                    />
                </a>
            </div>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.app-menu {
    background: $dark-bg url("../../images/joinsteer-bg-logo.svg") no-repeat
        bottom right / 16rem auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100dvh;
    z-index: 110;
    max-width: 32rem;
    transition: width 0.3s ease-in-out;
    color: #fff;
    overflow: hidden;

    &--open {
        width: min(100vw, 32rem);
    }

    &__wrapper {
        padding: 2rem;
        width: min(calc(100vw - 4rem), 28rem);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 4rem);
        overflow: hidden auto;
    }

    &__section ~ .app-menu__section {
        margin-top: 0.5rem;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        padding-top: 1.5rem;
    }
    &__section--make {
        flex-grow: 1;
    }

    &__close-button {
        position: absolute;
        z-index: 2;
        top: 2rem;
        right: 2rem;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        display: block;

        img {
            vertical-align: top;
        }
    }

    &__title {
        font-family: $bebas;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 1.28px;
        opacity: 0.5;
        margin: 0 0 1.2rem;
    }

    &__link {
        cursor: pointer;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.1;
        margin-bottom: 0.8rem;
        display: block;
        text-decoration: none;
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0;
    }

    &__social-link {
        margin-right: 0.5rem;
    }

    @include medium-down {
        &__section--make {
            .list {
                column-count: 2;
            }
        }
    }

    @include medium-up {
        &__wrapper {
            padding: 2rem 2.5rem;
        }

        &__section ~ .app-menu__section {
            margin-top: 1rem;
            padding-top: 2rem;
        }
    }
}
</style>
