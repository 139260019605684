<script setup lang="ts">
import { computed } from "vue";
import { VueTelInput } from "vue-tel-input";
import Label from "@/Components/Forms/Label.vue";
import Error from "@/Components/Forms/Error.vue";
import "vue-tel-input/vue-tel-input.css";

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: "",
    },
    label: {
        type: String,
        default: "",
    },
    required: {
        type: Boolean,
        default: false,
    },
    message: {
        type: String,
        default: "",
    },
});

interface TelInputOptions {
    placeholder?: string;
    type?: string;
}

const telInputOptions: TelInputOptions = {
    placeholder: props.placeholder,
    type: "tel",
};

const phoneNumber = computed({
    get: () => props.modelValue,
    set: (newValue) => emit("update:modelValue", newValue),
});

const emit = defineEmits<{
    (e: "update:modelValue", value: string): void;
}>();
</script>

<template>
    <div class="input__field">
        <Label :label="label" :required="required" />
        <vue-tel-input
            :input-options="telInputOptions"
            v-model="phoneNumber"
            mode="international"
            default-country="FR"
            class="input"
            :class="{ input__error: message }"
        ></vue-tel-input>

        <Error :error="message" />
    </div>
</template>

<style lang="scss">
.vue-tel-input {
    font-family: $bebas;
    border: 1px solid $grey-input;
    border-radius: 4px;
    background: white;

    &:focus-within {
        box-shadow: none;
        outline: none;
        border-color: $dark-bg;
    }

    .vti__dropdown {
        padding: 0;
    }
    .vti__input {
        padding: 0 0.5rem;
    }
    &.input {
        &__error {
            border: 1px solid $error;
            input {
                color: $error;
            }
        }
    }
}
</style>
