const urlParams = new URLSearchParams(window.location.search);

const gclid = urlParams.get("gclid");
const fbclid = urlParams.get("fbclid");

if (gclid) {
    console.debug("GCLID found, storing it", gclid);
    localStorage.setItem("gclid", gclid);
}
if (fbclid) {
    console.debug("FBCLID found, storing it", gclid);
    localStorage.setItem("fbclid", fbclid);
}
