<script setup>
import PhoneInput from "@/Components/Forms/Input/PhoneInput.vue";
import Checkbox from "@/Components/Forms/Input/Checkbox.vue";
import Button from "@/Components/Buttons/Button.vue";
import { useForm, usePage } from "@inertiajs/vue3";
import { computed } from "vue";

const emit = defineEmits(["cancelled", "registered"]);
const page = usePage();

const props = defineProps({
    channel: {
        type: String,
        default: "",
    },
});

const form = useForm({
    phone: "",
    accept: false,
});

const fillFormFromLoginAttempt = (data) => {
    form.phone = data.identifier;
};

defineExpose({ fillFormFromLoginAttempt });

const submitRegisterForm = () => {
    form.clearErrors();
    form.transform((data) => ({
        ...data,
        channel: props.channel,
    })).post(route("auth.register"), {
        onSuccess: () => {
            emit("registered", {
                phone: form.phone,
                code: page.props.flash.code,
            });
        },
    });
};

const cancel = () => {
    form.reset();
    emit("cancelled");
};

const formFilled = computed(() => {
    return form.phone && form.accept;
});
</script>

<template>
    <form @submit.prevent="submitRegisterForm">
        <PhoneInput
            id="phone"
            v-model="form.phone"
            type="text"
            :label="$t('forms.whatsYour', { attr: $t('attributes.phone') })"
            required
            placeholder="06 00 00 00 00"
            :message="form.errors.phone"
        />

        <label class="register__accept">
            <Checkbox v-model:checked="form.accept" :value="true" />
            <span class="register__accept--label">
                {{ $t("iAcceptThe") }}
                <a :href="route('privacy')" target="_blank">
                    {{ $t("cgu") }}
                </a>
            </span>
        </label>

        <div class="register__action">
            <Button
                variante="primary"
                type="submit"
                :disabled="form.processing || !formFilled"
            >
                {{ $t("actions.signUp") }}
            </Button>

            <Button variante="tertiary" type="button" @click="cancel">
                {{ $t("actions.cancel") }}
            </Button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.register {
    &__accept {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        &--label {
            font-size: 0.8rem;
        }
    }
}
</style>
