<script setup>
import PhoneInput from "@/Components/Forms/Input/PhoneInput.vue";
import { useForm, usePage } from "@inertiajs/vue3";
import Button from "@/Components/Buttons/Button.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits(["identifierNotFound", "otpFormSubmitted"]);
const page = usePage();

const form = useForm({
    channel: "phone",
    identifier: "",
});

const submitOtpForm = () => {
    form.clearErrors();
    form.post(route("auth.otp"), {
        onSuccess: () => {
            emit("otpFormSubmitted", {
                channel: form.channel,
                identifier: form.identifier,
                code: page.props.flash.code,
            });
            resetFormFields();
        },
        onError: (data) => {
            if (data.notfound) {
                emit("identifierNotFound", {
                    channel: form.channel,
                    identifier: form.identifier,
                });
            }
        },
    });
};

const resetFormFields = () => {
    form.identifier = "";
};
</script>

<template>
    <form @submit.prevent="submitOtpForm">
        <PhoneInput
            id="phone"
            v-model="form.identifier"
            type="text"
            :label="t('forms.whatsYour', { attr: t('attributes.phone') })"
            required
            placeholder="06 00 00 00 00"
            :message="form.errors.identifier"
        />

        <div v-if="form.errors.exception">
            <p class="error">
                {{ t("forms.exceptionError") }}
            </p>
        </div>

        <div class="register__action">
            <Button
                variante="primary"
                type="submit"
                :disabled="form.identifier === '' || form.processing"
            >
                {{ t("actions.continue") }}
            </Button>
        </div>
    </form>
</template>

<style lang="scss">
.register {
    &__link {
        margin: 1rem auto;
        width: fit-content;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0em;
        font-weight: 400;
        &--action {
            display: flex;
            justify-content: center;
            color: $dark-font;
            cursor: pointer;
            margin: 1rem auto;
            width: fit-content;
            font-weight: 700;
            text-decoration: underline;
            appearance: none;
            background-color: transparent;
            border: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__action {
        padding-top: 0.75rem;
        display: grid;
        gap: 1rem;

        > * {
            width: 100%;
        }
    }
}
</style>
