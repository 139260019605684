type Star = {
    index: number;
    rating: number;
    fillColor?: string;
    emptyColor?: string;
};

// Détermination du nombre d'étoiles à mettre en jaune
export const getStarColor = ({
    index,
    rating,
    fillColor = "#E8FF2E",
    emptyColor = "#D9DCDC",
}: Star) => {
    // On vérifie la note et on retourne la bonne couleur pour l'étoile
    return index <= Math.ceil(rating) ? fillColor : emptyColor;
};
