<script setup lang="ts">
import { watch } from "vue";

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["close"]);

const closeModal = () => {
    emit("close");
};

watch(
    () => props.show,
    () => {
        document.body.style.overflow = props.show ? "hidden" : "";
    },
);
</script>

<template>
    <Teleport to="body">
        <div v-show="show" class="modal">
            <div class="modal-overlay" @click="closeModal"></div>
            <div class="modal-content">
                <button class="modal-content__close" @click="closeModal">
                    <img
                        src="../../images/icons/close_modal.svg"
                        class=""
                        width="24"
                        height="24"
                        alt="close modal"
                    />
                </button>

                <div v-if="$slots.title" class="modal__title">
                    <slot name="title"></slot>
                </div>
                <slot name="content"></slot>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: $dark-font;

    &__title {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        padding-right: 2.5rem;
    }
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 1.5rem;
    border-radius: 4px;
    width: 16rem;
    &__close {
        cursor: pointer;
        background: none;
        border: none;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
    }

    @include medium-up {
        width: 20rem;
    }

    @include large-up {
        width: 36rem;
    }
}
</style>
