<script lang="ts" setup>
import StarFull from "@/Components/Icons/StarFull.vue";
import { usePage } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
import { getStarColor } from "@/helpers/rating";

const { t } = useI18n();
const page = usePage();
</script>

<template>
    <a
        href="https://maps.app.goo.gl/THnnh3J2TW3MKAAt7"
        class="rating"
        target="_blank"
    >
        <div class="rating__content">
            <p class="rating__number text">
                {{ page.props.googleRating.rating }}
            </p>
            <!-- Loop through the rating to display stars -->
            <div class="rating__icon" v-for="index in 5" :key="index">
                <StarFull
                    :color="
                        getStarColor({
                            index: index,
                            rating: page.props.googleRating.rating,
                        })
                    "
                />
            </div>
        </div>
        <p class="rating__total">
            {{
                t("basedOnGoogleRatings", {
                    count: page.props.googleRating.user_ratings_total,
                })
            }}
        </p>
    </a>
</template>

<style lang="scss" scoped>
.rating {
    padding: 0.5rem 1rem;
    border: 1px solid #809ea3;
    border-radius: 8px;
    width: fit-content;
    margin: 0 auto 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    @include medium-up {
        width: auto;
        align-items: flex-start;
    }
    &__content {
        font-family: $bebas;
        display: flex;
        align-items: center;
        align-content: center;
    }
    &__number {
        color: #fff;
        font-size: 2rem;
        font-weight: 700;
        margin: 0 1rem 0 0;
    }
    &__total {
        margin: 0;
        color: white;
        opacity: 0.5;
    }
    &__icon {
        display: flex;
    }
    &:hover {
        text-decoration: none;
    }
}
</style>
