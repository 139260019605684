<script setup lang="ts">
import { PropType } from "vue";
import { HTMLInputTypeAttribute } from "@/types";
import Label from "@/Components/Forms/Label.vue";
import Error from "@/Components/Forms/Error.vue";

type VarianteTextInput = "classique" | "facette";

defineProps({
    modelValue: {
        type: String as PropType<string | null | undefined>,
        default: null,
        required: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    pattern: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: "",
    },
    required: {
        type: Boolean,
        default: false,
    },
    message: {
        type: String,
        default: "",
    },
    type: {
        type: String as PropType<HTMLInputTypeAttribute>,
        default: "text",
    },
    variante: {
        type: String as PropType<VarianteTextInput>,
        default: "classique",
    },
    min: {
        type: Number,
        default: null,
    },
});

const emit = defineEmits<{
    (e: "update:modelValue", value: string): void;
}>();

const updateModelValue = (event: Event) => {
    emit("update:modelValue", (event.target as HTMLInputElement).value);
};
</script>

<template>
    <div :class="`input__field input__field--${variante}`">
        <Label :label="label" :required="required" />
        <input
            :type="type"
            :value="modelValue"
            @input="updateModelValue"
            ref="input"
            :placeholder="placeholder"
            :pattern="pattern"
            :min="min"
            :class="{
                input: true,
                [`input--${type}`]: true,
                input__error: message,
            }"
        />

        <Error :error="message" />
    </div>
</template>

<style lang="scss">
.input {
    font-family: $bebas;
    font-size: 1rem;
    border: 1px solid $grey-input;
    border-radius: 4px;
    font-weight: 400;
    letter-spacing: 0.08em;
    text-align: left;
    max-height: 3rem;
    padding: 0.75rem;

    &::placeholder {
        color: rgba($color: $dark-font, $alpha: 0.5);
    }

    &:focus {
        box-shadow: none;
        outline: none;
        border-color: $dark-bg;
    }

    &__field {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        &--facette input {
            font-family: $roboto;
            letter-spacing: normal;
        }
    }
    &__error {
        border: 1px solid $error;
        &::placeholder {
            color: $error;
        }
    }

    &--search {
        padding: 1rem 1rem 1rem calc(2rem + 24px);
        background: #fff url("../../../../images/icons/search.svg") no-repeat
            left 1rem center / 24px;

        @media (min-width: 960px) {
            .marketplace-sidebar & {
                padding: 1rem 1rem 1rem calc(1.25rem + 14px);
                background: #fff url("../../../../images/icons/search.svg")
                    no-repeat left 1rem center / 14px;
                font-size: 14px;
            }
        }
    }
}
</style>
