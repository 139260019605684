<script setup lang="ts">
import { computed, defineAsyncComponent, PropType } from "vue";
type VarianteButtonType =
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quinary"
    | "arrow"
    | "danger"
    | "primary-video"
    | "secondary-video";
type VarianteButtonIcon = "Star" | "Plus" | "SingleRightArrow" | "MinusAlgolia";

const props = defineProps({
    leftIcon: {
        type: String as PropType<VarianteButtonIcon>,
        default: null,
    },
    rightIcon: {
        type: String as PropType<VarianteButtonIcon>,
        default: null,
    },
    iconSize: {
        type: String as PropType<"regular" | "small">,
        default: "regular",
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    as: {
        type: [String, Object],
        default: "button",
    },
    isAnimated: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String as PropType<"small" | "regular">,
        default: "regular",
    },
    variante: {
        type: String as PropType<VarianteButtonType>,
        default: "primary",
    },
});

const iconSizeValue = props.iconSize === "regular" ? 24 : 10;

const icon = computed(() => {
    if (!props.leftIcon && !props.rightIcon) return null;

    return defineAsyncComponent(
        () =>
            import(
                `../Icons/${
                    props.leftIcon ? props.leftIcon : props.rightIcon
                }.vue`
            ),
    );
});
</script>

<template>
    <component
        :is="props.as"
        :class="[
            `button button__${variante}`,
            { '-small': size === 'small', '-isAnimated': isAnimated },
        ]"
        :disabled="disabled"
    >
        <span
            class="button__content"
            :class="{
                '-smallIcon': iconSize === 'small',
            }"
        >
            <component
                :is="icon"
                :width="iconSizeValue"
                :height="iconSizeValue"
                v-if="leftIcon"
            />
            <slot />
            <component
                :is="icon"
                :width="iconSizeValue"
                :height="iconSizeValue"
                v-if="rightIcon"
            />
        </span>
    </component>
</template>

<style lang="scss" scoped>
@keyframes pulse-button {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($primary, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba($primary, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($primary, 0);
    }
}
.button {
    display: flex;
    justify-content: center;

    cursor: pointer;
    appearance: none;
    color: $dark-font;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        &.-smallIcon {
            gap: 0.25rem;
        }
    }
    &__primary {
        background-color: $primary;
        border: none;
        &:hover,
        &:focus {
            background-color: $primary-hover;
            transition: background-color $transition-time;
        }
        &:disabled {
            background-color: $primary;
            transition: background-color $transition-time;
            opacity: 40%;
            cursor: default;
        }
    }

    &__arrow {
        display: inline-block;
        padding: 0.65rem 2rem;
        @include arrow-background($primary);
        transform: translateX(0%);
        transition: transform 0.2s ease-in-out;
        &:hover {
            transition: transform 0.2s ease-in-out;
            transform: translateX(10%);
        }
    }
    &__secondary {
        background-color: transparent;
        border: 1px solid $light-bg;
        &:hover,
        &:focus {
            border-color: $dark-bg;
            transition: border-color $transition-time;
        }
        &:disabled {
            opacity: 20%;
        }
    }
    &__tertiary {
        background-color: transparent;
        border: none;
        padding: 0;
        &:hover,
        &:focus {
            border-bottom: 1px solid $dark-font;
            transition: border $transition-time;
        }
        &:disabled {
            opacity: 20%;
        }
    }
    &__quaternary {
        background-color: transparent;
        padding: 0.5rem 1rem;
        border: 1px solid $light-bg;
        border-radius: 2rem;
        &:hover,
        &:focus {
            border-color: $dark-font;
            transition: border $transition-time;
        }
        &:disabled {
            opacity: 20%;
        }
    }
    &__quinary {
        background-color: $dark-bg;
        border: 1px solid $dark-bg;
        color: $primary;
        box-sizing: border-box;
        &:hover,
        &:focus {
            transition: border-color $transition-time;
        }
        &:disabled {
            opacity: 20%;
        }
    }
    &__danger {
        color: $red-font;
        background-color: #f2deda;
        text-decoration: none;
        border: none;
        &:disabled {
            opacity: 20%;
        }
    }
    &__primary-video {
        background: transparent;
        border: 2px solid $primary;
        border-radius: 1.5rem;
        color: $primary;
        text-transform: uppercase;
        padding: 0.5rem 1.25rem;
        font-size: 1.5rem;
        display: inline-flex;
        align-self: center;
        &.-small {
            font-size: 0.9375rem;
        }
        &.-isAnimated {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
            transform: scale(1);
            animation: pulse-button 1s infinite;
        }
    }
    &__secondary-video {
        background: transparent;
        border: 1px solid white;
        border-radius: 0.25rem;
        color: white;
        padding: 0.5rem 1rem;
        display: inline-flex;
        align-self: center;
    }
}
</style>
