export const fr = {
    home: {
        home: "Accueil",
        accelerate: {
            cta: "Testez par vous-même.\n",
            mainTitle: "Allez plus loin",
            tags: ["start", "now"],
            title: [
                "UN VÉHICULE HORS NORME ",
                "À DES CONDITIONS ",
                "HORS NORME",
            ],
            text: [
                "Pour les financements aussi, nous visons la performance.",
                "Il vous suffit de définir un budget mensuel dans notre simulateur en ligne.\n Nous définissons pour vous, avec nos experts, la meilleure mensualité.\n Vous accédez à un large catalogue de véhicules disponibles et  nous vous livrons partout en France.",
            ],
        },
        aboutUs: "A propos de Joinsteer",
        FAQ: "FAQ",
        animation: {
            header: {
                button: "Marketplace",
            },
            step0: {
                title: ["LES RÊVES NE ", "S'ACHÈTENT PAS."],
            },
            stepEnter: {
                title: ["ILS SE VIVENT."],
            },
            step1: {
                title: [
                    "CHOISISSEZ LE VÉHICULE QUI ",
                    "VOUS CORRESPOND PARMI ",
                    "DES DIZAINES DE MILLIERS.",
                ],
                title2: [
                    "SOYEZ LIBRE DE CHOISIR ",
                    "LE VÉHICULE QUI ",
                    "VOUS CORRESPOND",
                ],
            },
            step2: {
                title: ["DANS DES ", "CONDITIONS ", "EXCEPTIONNELLES."],
            },
            step3: {
                stories: [
                    {
                        title: "Top Gun",
                        text: "«Pourquoi la Porsche 356 dans 'Top Gun' ? Parce qu'elle est audacieuse et intemporelle, tout comme nos pilotes. Elle a du caractère, de la classe, et ne demande qu'à être maîtrisée. C'est plus qu'une voiture, c'est un symbole de puissance et de contrôle, un pur classique qui se distingue dans un monde de vitesse et d'adrénaline.»",
                        legend: "SELON MAGAZINE EMPIRE \n DIXIT TONY SCOTT",
                    },
                    {
                        title: "Californication",
                        text: "«Conduire ma 911, c'est comme une aventure d'une nuit : excitante, un peu imprudente, et toujours avec une belle finition. Et le meilleur ? Elle ne me demande jamais où ça va nous mener.»",
                        legend: "CALIFORNICATION \n EPISODE 4 SAISON 3 \n DIXIT HANK MOODY",
                    },
                    {
                        title: "Bad Boy's",
                        text: "«Dans 'Bad Boys', la Porsche 964 Turbo était plus qu'une voiture; elle était un personnage à part entière. Je voulais quelque chose qui incarne la vitesse, le luxe et la puissance - tout ce que nos héros représentent. La 911 Turbo a cette présence à l'écran qui capte l'essence de Miami et de l'action explosive. C'était le choix parfait pour compléter l'énergie et le style de vie des personnages.»",
                        legend: "SELON MAGAZINE EMPIRE \n EN 2022 \n DIXIT MICHAEL BAY",
                    },
                ],
            },
            modal: "Êtes-vous sûr de vouloir quitter ?",
            scan: "Scan",
            actions: {
                enter: "Entrer",
                exit: "Sortir",
                chooseCar: "Lancer le scan",
                startEngine: "Start \n engine",
            },
        },
        car: {
            body: "Carrosserie",
            interior: "Intérieur",
            mecanical: "Mécanique",
            trace: "Traçabilité",
            tyre: "Pneumatiques",
        },
        dream: {
            cta: "Let's go",
            subtitle: ["Les rêves ne s’achètent pas, ils se vivent.\n"],
            text: [
                "Dénicher, garantir, renouveler.",
                "JOINSTEER scanne toute l’Europe pour trouver le véhicule de vos rêves, puis vous le délivre dans les meilleures conditions techniques et financières. Et nous renouvelons cette opération, autant de fois que vous voulez renouveler votre voiture.",
            ],
        },
        footer: {
            first_part: "Votre partenaire automobile Premium idéal.",
            second_part:
                "Trouvez en quelques clics la voiture de vos rêves à l’achat, en leasing, loa ou location longue durée, dès 500€ par mois.",
            third_part:
                "On simplifie pour vous votre achat de A à Z, obtenez une date de livraison de votre future voiture instantanément.",
            fourth_part:
                "Votre future voiture à portée de main ? Oui, c’est possible. Rejoignez la révolution Joinsteer dès maintenant. Nous nous occupons de tout à votre place, de la garantie, à la révision jusqu'à la livraison tout en vous garantissant un service de haute qualité.",
        },
        hero: {
            title: ["Découvrez des rêves ", "  qui maintiennent ", " éveillés"],
        },
        passion: {
            cta: "Let's go",
            tags: ["No Brand", "No Stock"],
            title: ["L’AUTOMOBILE, ", "C’EST L'INDÉPENDANCE."],
            text: [
                "Aucun stock ou aucune marque ne peut se mettre en travers de vos rêves.",
                "JOINSTEER ne dépend d’aucune marque et d’aucun stock, donc vous non plus. Vous pouvez à tout moment changer de véhicule, au rythme de vos envies.",
            ],
        },
        requirement: {
            cta: "Faisons connaissance",
            mainText: [
                "Au delà des standards. ",
                "Nous sélectionnons nos véhicules parmi un réseau constructeur, mais nous ne nous arrêtons pas là. Nous garantissons un véhicule “zéro défaut”, en révisant et contrôlant nous-mêmes les véhicules, en plus du constructeur. Nous donnons plus pour vous, sans perdre le moindre jour à la livraison.",
            ],
            subtitle: ["LA PERFECTION EST ", "NOTRE UNIQUE OPTION."],
            text: [
                "Nous sommes vos copilotes. \n",
                "Nous prenons la responsabilité de votre véhicule, dont nous assurons la garantie. À la moindre question ou prise en charge, un interlocuteur JOINSTEER répondra présent.",
            ],
        },
        storyTelling: {
            title: ["LA PASSION DE ", "CASSER LES CODES."],
            stories: [
                {
                    title: "POUR LES PASSIONNÉS, PAR DES PASSIONNÉS",
                    content:
                        "Alexis Berthoud et Thibaud Carrai, fondateurs et directeurs de JOINSTEER, viennent tous les deux de l’automobile, l’un dans la tech, l’autre dans le sport. En 2019, ils s’associent pour simplifier l’accès au marché de l’automobile : plus avantageux, accessible et surtout plus excitant.",
                    link: "Découvrez leur histoire",
                },
                {
                    title: "LE DIGITAL, \nNOTRE MOTEUR",
                    content:
                        "Pour révolutionner un secteur poussiéreux et en retard dans l’expérience utilisateur, JOINSTEER a développé les meilleures technologies digitales pour les mettre au niveau d'exigence des passionnés d’automobile. ",
                    link: "Explorez la marketplace",
                },
                {
                    title: "LE NEUF EN CONCESSION, \n C’EST DE L’HISTOIRE ANCIENNE",
                    content:
                        "Retrouvez la magie de changer de voiture, nous nous occupons du reste. Oubliez le financement, la revente, l’entretien, l’administratif, les visites et essais décevants, les négociations, l’attente de la livraison… Acheter un véhicule appartient désormais au passé.",
                    link: "Le service JOINSTEER",
                },
            ],
        },
        technology: {
            cta: "Challengez-la",
            text: [
                "Si une voiture n’est pas chez Joinsteer, elle n’existe pas. ",
                "Nous avons développé une plateforme technologique unique, qui repère pour vous, à travers toute l’Europe, les véhicules qui correspondent le mieux à vos demandes. Nous sélectionnons parmi ceux-ci les meilleures opportunités. Vous pouvez ainsi choisir toutes les configurations sur les modèles qui vous font rêver, et les obtenir simplement et rapidement chez vous. ",
            ],
            title: ["La technologie ", "la plus puissante ", "du marché"],
        },
    },
    account: {
        accountManagement: "Gestion du compte",
        financingRequests: "Mes demandes de financement",
        informations: "Mes informations",
        myAccount: "Mon compte",
        security: "Sécurité",
        deleteJoinsteerAccount: "Supprimer mon compte Joinsteer",
        sureToDelete: "Voulez-vous vraiment supprimer votre compte Joinsteer ?",
        confirmDelete:
            "Cette action est irréversible. Toutes vos informations seront supprimées, vous ne pourrez plus accéder à vos collections et vos dossiers de financement.",
    },
    toast: {
        success: "Informations sauvegardées",
        urlCopied: "URL copiée dans votre presse papier",
        logged: "Vous êtes maintenant connecté",
        deleteSavedSearch: "Recherche sauvegardée supprimée avec succès",
        deleteSimulation: "Demande de financement supprimée avec succès",
    },
    energyType: {
        diesel: "Diesel",
        electric: "Électrique",
        essence: "Essence",
        hybrid: "Hybride",
    },
    searchMake: "Rechercher une marque…",
    searchModel: "Rechercher un modèle…",
    searchSpecification: "Rechercher un modèle spécifique…",
    searchOption: "Rechercher une option…",
    searchColor: "Rechercher une couleur…",
    desiredMonthlyRent: "Loyer mensuel souhaité",
    toggleMenu: "Ouvrir le menu",
    logoJoinsteer: "Joinsteer Logo",
    iAcceptThe: "J'accepte les",
    cgu: "conditions générales d'utilisation",
    basedOnGoogleRatings: "sur {count} avis Google",
    toggleFilter: "Afficher / Masquer les filtres",
    sortBy: "Trier",
    filter: "Filtrer",
    saveSearch: "Sauvegarder la recherche",
    personalizeSearch: "Personnalisez le titre de votre recherche",
    titleSearch: "Titre de la recherche",
    save: "Sauvegarder",
    savedSearchConfirm: "Recherche sauvegardée",
    dynamicContent: {
        emptyMessage: "Pas de contenu pour le moment",
    },
    noCurrentSearch:
        "Aucun filtre actif. \n Merci d'effectuer une nouvelle recherche.",
    performSavedSearch: "Effectuer une recherche",
    savedSearchConfirmText:
        "Votre recherche a bien été sauvegardée. Nous vous enverrons les nouveaux véhicules disponibles sur votre adresse email.",
    manageSavedSearches: "Gérer mes recherches sauvegardées",
    deleteSavedSearch: "Supprimer la recherche sauvegardée",
    nbVehicleHit: "{ nbHits } véhicules disponibles à la livraison",
    vehicles: "véhicules",
    nbVehicleAvailable: "{ nbHits } {designation} disponibles",
    recentSearches: "Recherches récentes",
    quotedVehicles: "Modèles cotés",
    iconicBrands: "Marques emblématiques",
    noResults: "Pas de résultats",
    profile: {
        job: "Votre profession",
        contract: "Votre contrat",
        fiscal_reference_income: "Votre revenu fiscal de référence",
        average_income: "Votre revenu moyen les 12 derniers mois",
        monthly_charges: "Vos charges mensuelles",
        credit_monthly_payment: "Votre mensualité de crédit en cours",
    },
    co_contractor: {
        firstname: "Prénom de votre co-contractant",
        lastname: "Nom de votre co-contractant",
        job: "Profession de votre co-contractant",
        birthday_at: "Date de naissance de votre co-contractant",
    },
    company: {
        director: "Nom du dirigeant de l’entreprise",
        name: "Nom de l’entreprise",
        siret: "Siret de l’entreprise",
        address: "Adresse de l’entreprise",
        share_capital: "Capital social de l’entreprise",
        lunch_at: "Date de création de l’entreprise",
        sector: "Secteur de l’entreprise",
        turnover: "Chiffre d’affaire de l’entreprise en {currentYear}",
        operating_result:
            "Résultat d’exploitation de l’entreprise en {currentYear}",
        monthly_leasing_charges: "Mensualité des leasing en cours",
    },
    vehicle: {
        circulationYear: "Année de mise en circulation",
        discoverMonthlyPrice: "Découvrez votre mensualité",
        choose: "Sélectionnez ce véhicule",
        energyType: "Carburant",
        equipment: "Équipements",
        exteriorColor: "Couleur extérieure",
        forYou: "Ce véhicule est fait pour vous ?",
        filters: "Filtres",
        fromPrice: "Dès ",
        make: "Marque",
        maximalPower: "Puissance maximale",
        mileage: "Kilométrage",
        model: "Modèle",
        monthlyPrice: "Mensualité",
        options: "Options",
        perMonth: " / mois",
        specification: "Finition",
        year: "Année",
    },
    filters: {
        minYear: "Minimum",
        maxYear: "Maximum",
        maxMonthlyPrice: "Loyer mensuel souhaité",
        minMileage: "Minimum",
        maxMileage: "Maximum",
    },
    attributes: {
        firstname: "prénom",
        lastname: "nom",
        email: "adresse email",
        phone: "numéro de téléphone",
        code: "code de sécurité",
        job: "profession",
        fiscal_reference_income: "revenu annuel déclaré en",
    },
    label: {
        firstname: "Votre prénom",
        lastname: "Votre nom",
        email: "Votre adresse email",
        phone: "Votre numéro de téléphone",
        birthday_at: "Votre date de naissance",
        save: "Enregistrer les modifications",
        address: "Votre adresse",
    },
    forms: {
        whatsYour: "Quel est votre {attr} ?",
        whatsYourE: "Quelle est votre {attr} ?",
        your: "Votre {attr}",
        exceptionError:
            "Un problème est survenu, merci de réessayer ultérieurement.",
    },
    actions: {
        hide: "Masquer",
        showMore: "En savoir plus",
        cancel: "Annuler",
        signUp: "S'inscrire",
        signIn: "Se connecter",
        signInV2: "Connectez-vous",
        sendAgain: "Renvoyer à nouveau",
        continue: "Continuer",
        signInWithEmail: "Se connecter avec une adresse email",
        signInWithPhone: "Se connecter avec un numéro de téléphone",
        displayAllPhotos: "Afficher toutes les photos",
        copyLink: "Copier le lien",
        shareVehicle: "Partager ce véhicule",
        reset: "Réinitialiser",
        selectAll: "Tout sélectionner",
        yes: "Oui",
        no: "Non",
        customizeMonthlyPrice: "Calculez votre mensualité",
        leave: "Quitter",
        validate: "Valider",
        validateInfos: "Valider vos informations",
        rename: "Renommer",
        showingMore: "Voir plus",
        showingLess: "Voir moins",
    },
    messages: {
        noMakeSelected: "Vous devez d’abord choisir une marque",
        doNotReceiveCode: "Vous n’avez pas reçu de code ?",
        newCodeSended: "Nouveau code envoyé !",
        fillWithCodeSended:
            "Renseigner le code de sécurité envoyé au {identifier}",
        fillWithCodeSendedV2:
            "Renseigner le code de sécurité envoyé à {identifier}",
        securityCode: "Code de sécurité",
    },
    menu: {
        admin: "Administration",
        myAccount: "Mon compte",
        yourAccount: "Votre compte",
        mySavedSearches: "Mes recherches sauvegardées",
        myCollections: "Mes collections",
        mySimulations: "Mes demandes de financement",
        faq: "FAQ",
        login: "Se connecter / S’inscrire",
        logout: "Se déconnecter",
        makes: "Nos marques",
        socialNetworks: "Nos réseaux sociaux",
        marketplace: "Nos véhicules",
        aboutUs: "À propos de Joinsteer",
    },
    sorts: {
        default: "Par défaut",
        priceAsc: "Prix ordre croissant",
        priceDesc: "Prix ordre décroissant",
        mileageAsc: "Kilométrage ordre croissant",
        mileageDesc: "Kilométrage ordre décroissant",
        powerAsc: "Puissance ordre croissant",
        powerDesc: "Puissance ordre décroissant",
        yearAsc: "Année ordre croissant",
        yearDesc: "Année ordre décroissant",
    },
    landingPage: {
        title: "Votre sélection {make} en location longue durée dès {minMonthly} avec Joinsteer",
        filters: "{total } {make} à partir de {minMonthly}/mois",
        filtersItem: "{count} modèle|{count} modèles",
        text: "De {minMonthly} à {maxMonthly}/mois",
        textSingle: "Dès {minMonthly}/mois",
        vehicleCount:
            "{count} véhicule disponible|{count} véhicules disponibles",
        topTitle: "Le top LLD {make} en location longue durée",
        topSubtitle:
            "Vous hésitez encore ? Voici les modèles {make} qui ont le plus de succès en location longue durée chez nos clients. ",
        footer: {
            first_part: "Votre partenaire {make} idéal.",
            second_part:
                "Trouvez en quelques clics la {make} de vos rêves en location longue durée, dès {minPrice}€ par mois.",
            third_part:
                "On simplifie pour vous la location longue durée de A à Z, obtenez une date de livraison de votre future voiture instantanément.",
            fourth_part:
                "Une {make} à portée de main ? Oui, c’est possible. Rejoignez la révolution Joinsteer dès maintenant. Nous nous occupons de tout à votre place, de la garantie, à la révision jusqu'à la livraison tout en vous garantissant un service de haute qualité.",
            fifth_part: "Votre partenaire pour l’achat de votre {make} idéal.",
        },
    },
    tunnel: {
        coFounder: "Co-fondateur",
        yourCar: "Votre véhicule",
        hello: "Bonjour, je m’appelle Alexis.",
        needSomeInformations:
            "Il ne nous faut que quelques informations pour calculer votre mensualité.",
        accountAlreadyExist: "Vous avez déjà un compte Joinsteer ?",
        yourMonthlyPrice: "Votre mensualité",
        haveLeasing: "Avez-vous un leasing ?",
        weSendYouCodeBySms:
            "Nous venons de vous envoyer un code de sécurité par SMS au numéro suivant : {code}",
        weSendYouCodeByEmail:
            "Nous venons de vous envoyer un code de sécurité par email sur l'adresse suivante : {email}",
        sendCodeByEmail: "Envoyer le code par email",
        wantToLeave: "Vous souhaitez interrompre votre demande ?",
        continueProcess: "Continuer ma demande",
        noProblem: "Aucun problème !",
        canRestartProcess:
            "Vous pourrez reprendre votre demande de dossier de financement à tout moment depuis votre compte, dans l’onglet demande en cours.",
        hereTheResult: "Votre simulation sur|Vos simulations sur",
        yourSimulation:
            "Votre simulation prix TTC en {offer} sur {duration} mois",
        askAccordFor:
            "Vous pouvez dès à présent demander votre pré-accord en ligne pour :",
        getPreApproval: "Obtenez un pré-accord en ligne",
        getPreApprovalMobile: "Personnalisez votre mensualité",
        getSimulationResumeByMail: "Obtenez votre offre personnalisée par mail",
        changeParametersLater:
            "Modifiez vos paramètres à tout moment pour obtenir une meilleure mensualité.",
        receiveResumeMail:
            "Vous allez recevoir votre proposition par mail selon les caractéristiques du financement demandé.",
        monthlyPriceComputing: "Mensualité en cours de calcul ..",
        computing: "Calcul en cours ..",
        breadcrumbs: {
            step: "Étape",
            profil: "Profil",
            simulation: "Simulation",
        },
        thanksName: "Merci {name}",
        joinsteerTakeOver: "Joinsteer prend le relais !",
        informationsVerifications:
            "Toutes vos informations sont désormais en cours de vérification.",
        before48h:
            "L'équipe Joinsteer revient vers vous dans moins de 48 heures.",
        priorityCallbackRequest: "Réserver maintenant",
        goToSimulations: "Consulter mes demandes de financement",
        finalAgreement:
            "Accord définitif communiqué par la banque après études des pièces justificatives…",
        callbackRequestReceived: "Demande de rappel reçue",
        incorrectParameter: "Paramètre incorrect",
        unknow: "Inconnue",
        computeYoutMonthlyPrice: "Calculez votre mensualité",
        chooseRevision: "Choisissez l'offre qui vous convient",
    },
    calculator: {
        lld: "LLD",
        loa: "LOA",
        credit: "Crédit",
        duration: "Durée de financement",
        firstRent: "Première mensualité",
        annualMileage: "Kilométrage annuel",
        pricePlaceholder: "Indiquez un montant",
        loaAmount: "Option d'achat",
        vehiclePrice: "Prix du véhicule",
        typeOfFinancing: "Type de financement",
        errors: {
            excessiveFirstRent:
                "La première mensualité maximale autorisée est de {amount}€",
        },
    },
    savedSearch: {
        make: "Marque",
        models: "Modèles",
        specifications: "Spécifications",
        options: "Options",
        monthlyPrice: "Mensualité",
        colors: "Couleurs",
        registrationStart: "Année min",
        registrationEnd: "Année max",
        mileageStart: "Kilométrage min",
        mileageEnd: "Kilométrage max",
        energyTypes: "Carburant",
        query: "Recherche",
        datesSearch:
            "Recherche sauvegardée le {created_at}, dernière modification le {updated_at}.",
        activateNotification: "Activer les notifications par mail",
        mySavedSearches: "Mes recherches sauvegardées",
        deleteAction: "Cette action est irréversible.",
        confirmDelete:
            "Voulez-vous vraiment supprimer la recherche sauvegardée { title } ?",
        renameSavedSearch: "Renommer la recherche sauvegardée",
        modifySearch: "Modifier le titre de votre recherche",
        headerModal:
            "Connectez-vous ou créez votre compte Joinsteer pour enregistrer toutes vos recherches et sauvegarder vos véhicules favoris !",
    },
    simulation: {
        actions: {
            delete: "Supprimer la demande de financement",
            download: "Télécharger l'offre",
            edit: "Modifier les paramètres",
        },
        date: "Demande de financement du {created_at}",
        description:
            "{duration} mois de financement, {first_rent} € premier loyer, {mileage} km annuel",
        modal: {
            text: "Cette action est irréversible.",
            title: "Voulez-vous vraiment supprimer la demande de financement pour le véhicule “{vehicle}” ?",
            action: "Supprimer la demande de financement",
        },
        notFound: "Vous n'avez pas encore de demande de financement",
        shipping: "Livrable chez vous sous 3 semaines.",
        title: ["Constitution de ", "votre dossier"],
        visits: "Aucune visite | {count} visite | {count} visites",
        week: "cette semaine",
    },
    order: {
        callbackRequest: "Demande de rappel",
        title: ["Commande de ", "votre véhicule"],
        estimatedDelivery: "à partir du {date}",
        commercial: "Votre interlocuteur",
        role: "Commercial",
        waitingAction: "Action attendue de votre part",
        estimatedDeliveryOf: "Livraison estimée de votre",
        detailsVehicle: "Consulter la fiche produit du véhicule",
        notFound: "Vous n'avez pas encore de commande",
    },
    sections: {
        press: {
            title: "La presse parle de nous",
            titleLanding: "Joinsteer dans la presse",
        },
        faq: {
            title: "FAQ Joinsteer",
        },
        travelCompanion: {
            titleTabulate: {
                one: "Joinsteer ",
                two: "votre compagnon de route ",
                three: "attentif",
            },
            accordions: {
                premium: {
                    question: "Un service premium clé-en-main",
                    answer: "Plus besoin de faire de concession entre une voiture fiable et votre budget. Depuis votre réservation en ligne, jusqu'à la remise des clés, laissez-vous guider par notre équipe d’experts qui opère depuis notre QG en France. Nous nous occupons de tout, de la garantie, à la révision jusqu'à la livraison. Nous assurons nous même un nettoyage complet de votre {make}. Nos normes de livraison sont spécialement conçus pour les clients les plus exigeants",
                },
                handpicked: {
                    question: "Des partenaires {make} triés sur le volet",
                    answer: "Avant de rejoindre le réseau Joinsteer, chaque distributeur de {make} est passé par un rigoureux processus de sélection. Avant de vous proposer une sélection de {make} de ses meilleurs véhicules réalisés par nos soins, chaque partenaire a été minutieusement contrôlé.",
                },
                highQualityStandard: {
                    question: "Un haut standard de qualité",
                    answer: "Jusqu’à présent, en moyenne seul 1 distributeur sur 10 n’est à la hauteur de nos exigences. Même une fois approuvée, nous nous assurons du bon respect de notre charte qualité avant chaque livraison : carrosserie, mécanique, révision… C’est l’occasion de continuellement vérifier la qualité des produits de nos partenaires pour entretenir une relation de confiance vertueuse.",
                },
                perfectPartner: {
                    question: "Devenir votre partenaire {make} idéal",
                    answer: "Vous rêviez de pouvoir financer votre future {make} au meilleur prix qu'elle soit de collection, d’occasion ou encore électrique ? Joinsteer sera votre partenaire idéal. Nous vous garantissons le meilleur loyer du marché, un service de haute voltige pour vous permettre de réaliser vos rêves les plus fous.",
                },
            },
        },
        noMoreConcession: {
            titleTabulate: {
                one: "Joinsteer ",
                two: "ne faites plus de concession ",
                three: "pour votre nouvelle {make}",
            },
            subtitle: "Commandez aujourd’hui votre {make} préférée en LLD",
            description:
                "JOINSTEER ré-invente l’expérience d’acquisition de votre {make}. Découvrez la disponibilité des configurations les plus exclusives dans notre sélection. L’expertise de notre équipe répondra à tous vos questionnement et vous ouvrira de nouvelles perspectives en vous affranchissant des frontières sur le choix de votre {make}.",
            accordions: {
                qualityAssurance: {
                    question: "L'assurance de la qualité",
                    answer: "Réservez et commandez votre {make} préférée depuis le confort de votre canapé. Les véhicules d'occasion {make} offrent un standard de qualité et de fiabilité comparable à ceux d'un véhicule neuf. Ils sont soumis à un contrôle pour garantir leur sécurité, vous pouvez conduire en toute confiance, en sachant que vous tirerez le maximum de plaisir de votre {make}. \n\n Nous procédons jusqu'à 200 points de contrôle. Partez l’esprit serein, 100% de nos voitures sont assorties d’une garantie et sont entièrement révisées.",
                },
                maintenance: {
                    question: "Comment entretenir votre {make} ?",
                    answer: "Présentez-vous dans n’importe quelle concession {make} pour votre entretien ou une réparation sans avoir à vous soucier de la facture. Dès 14€/mois, tout est pris en charge: pièces, main d’oeuvre, consommable hors pneus uniquement.",
                },
                insurance: {
                    question: "Quelle assurance pour votre {make} ?",
                    answer: "Partez l’esprit serein, 100% de nos voitures sont assorties d’une garantie et sont entièrement révisées. Ne vous souciez pas d’un achat loin de chez vous et des problèmes qui vont avec.",
                },
            },
        },
        concept: {
            title: "Le concept \n Joinsteer",
            accordions: {
                ambition: {
                    question: "L’ambition de Joinsteer",
                    answer: "L'achat d’une {make} devient de plus en plus coûteux et est généralement considéré comme un investissement très important. Joinsteer souhaite changer les normes établies et fournir une nouvelle solution alternative innovante. Vous pouvez désormais louer la {make} de vos rêves à moindre coût, accéder à des véhicules de la marque {make} auxquels vous n’auriez jamais pensés grâce à notre algorithme de calcul des loyers inédit sur le marché ! La puissance de la data est au service de votre portefeuille. Pour finir, vous pourrez renouveler votre {make} plus régulièrement et facilement avec des offres moins engageantes.\n\n Disposer d’un véhicule sans en subir les contraintes, c’est l’envie de tout à chacun qui veut allier l’utile à l’agréable, sans se préoccuper du financement de son véhicule, de sa revente ou même de son entretien. Le coût d’usage a pris le pas sur la propriété. Du coup, pourquoi acheter une voiture lorsque l’on peut la louer ?\n \nPour répondre à l’ensemble de vos questions et besoins, Joinsteer vous propose plusieurs formules : la location avec option d’achat (LOA) ou la location longue durée (LLD). Trouver la bonne formule n'a jamais été aussi simple!",
                },
                customers: {
                    question: "Nos clients, notre préoccupation première",
                    answer: "Exclusivité, expertise, lifestyle !\n\nNotre ambition est de changer les codes de l’industrie automobile qui se doit d’évoluer. Joinsteer permet à chacun d'utiliser librement le véhicule de son choix avec des formules adaptées à chaque modèle, sous forme de location longue durée (LLD), la location avec option d’achat (LOA) autrement appelée leasing.\n\nPour répondre aux besoins des utilisateurs actuels et futurs, Joinsteer vous débarrasse des aspects ennuyeux et administratifs comme par exemple les visites de multiples concessionnaires qui prêchent tous pour leur marque, les négociations d'achat, la foire aux offres de financement, la maintenance ou encore l’engagement de certain contrat et parfois même à l’attente sans fin de la livraison.\n\nNotre vocation est de vous accompagner sur tous les points clefs liés à l’usage de votre voiture. Joinsteer construit une relation durable avec vous, tout en restant concentré sur vos besoins et en s'adaptant à vos souhaits et votre évolution de votre vie dépendant de votre situation professionnelle et familiale.",
                },
                personalisedService: {
                    question: "Un service ultra personnalisé",
                    answer: "L'ambition de Joinsteer est de concilier l'agilité et la réactivité d'une startup en plaçant la satisfaction de nos clients au cœur de notre préoccupation. Nous souhaitons, grâce à la technologie et aux outils modernes, vous proposer des solutions toujours plus compétitives et moins engageantes. Nous nous appuyons également sur un entourage composé d’ingénieurs automobile, pilotes et essayeurs afin de sélectionner pour vous les meilleurs modèles et configurations de chaque marque et ainsi assurer un faible coût d’utilisation grâce à une valeur sur le marché maîtrisée.",
                },
            },
        },
        story: {
            titleTabulate: {
                one: "TROUVEZ AUJOURD’HUI ",
                two: "VOTRE {make} DÈS {minMonth}",
            },
        },
        reviews: {
            title: "Vous & nous, \n vos avis sur l'expérience \n LLD {make}",
            description:
                "La relation client est au coeur de notre ADN car nos clients sont nos meilleurs ambassadeurs, depuis toujours. Dans l’expérience JOINSTEER, c’est votre avis qui compte.",
        },
    },
};
