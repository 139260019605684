<script lang="ts">
import { createWidgetMixin } from "vue-instantsearch/vue3/es";
import { connectSearchBox } from "instantsearch.js/es/connectors";
import { autocomplete } from "@algolia/autocomplete-js";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import porscheSvg from "../../../images/porsche.svg";
import ferrariSvg from "../../../images/ferrari.svg";
import audiSvg from "../../../images/audi.svg";
import mercedesSvg from "../../../images/mercedes.svg";
import mclarenSvg from "../../../images/mclaren.svg";
import lamborghiniSvg from "../../../images/lamborghini.svg";

const page = usePage();

import "@algolia/autocomplete-theme-classic";
import { VNode } from "vue";
import { useI18n } from "vue-i18n";
import { router, usePage } from "@inertiajs/vue3";

export default {
    props: {
        searchClient: {
            type: Object,
            required: true,
        },
        indexName: {
            type: String,
            required: true,
        },
        identifiant: {
            type: String,
            required: true,
        },
        research: {
            type: String,
            required: false,
            default: "",
        },
    },
    mixins: [createWidgetMixin({ connector: connectSearchBox })],
    mounted() {
        const { t } = useI18n();
        const searchClient = this.searchClient;
        const indexName = this.indexName;
        const identifiant = this.identifiant;
        const research = this.research;

        const { instantSearchInstance } = this;
        function setInstantSearchUiState({ query }) {
            instantSearchInstance.setUiState((uiState) => ({
                ...uiState,
                [indexName]: {
                    ...uiState[indexName],
                    page: 1,
                    query,
                },
            }));
        }

        function toMarketplace({ query }) {
            router.get("/marketplace", { query: query });
        }

        function updateAlgoliaState(item) {
            if (page.component === "Vehicle/Index") {
                instantSearchInstance.setUiState((uiState) => ({
                    ...uiState,
                    [indexName]: {
                        ...uiState[indexName],
                        page: 1,
                        menu: {
                            make: item.label,
                        },
                    },
                }));
            } else {
                const queryString = `?menu[make]=${encodeURIComponent(
                    item.label,
                )}`;
                window.location.href = `/marketplace${queryString}`;
            }
        }

        const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
            key: `instantsearch-${identifiant}`,
            limit: 3,
            transformSource({ source }) {
                return {
                    ...source,
                    onSelect({ item }) {
                        if (page.url.startsWith("/marketplace")) {
                            setInstantSearchUiState({ query: item.label });
                        } else {
                            toMarketplace({ query: item.label });
                        }
                    },

                    templates: {
                        ...source.templates,
                        item(params) {
                            const { item, html } = params;

                            const itemNode = source.templates.item(
                                params,
                            ) as unknown as VNode;

                            if (itemNode && itemNode.props) {
                                const trash =
                                    itemNode.props.children[1].props
                                        .children[0];

                                trash.props.children = html`
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.64962 4L4 4.64962L7.35038 8L4 11.3504L4.64962 12L8 8.64962L11.3504 12L12 11.3504L8.64962 8L12 4.64962L11.3504 4L8 7.35038L4.64962 4Z"
                                            fill="#D9DCDC"
                                        />
                                    </svg>
                                `;
                                return html`<span class="aa-ItemLink">
                                    ${item.label} ${trash}
                                </span>`;
                            } else {
                                return html`<span class="aa-ItemLink">
                                    ${item.label}
                                </span>`;
                            }
                        },
                    },
                };
            },
        });

        const displayRecentSearches = () => {
            if (recentSearchesPlugin.data) {
                const recentSearches = recentSearchesPlugin.data.getAll();
                return Array.isArray(recentSearches)
                    ? recentSearches.length
                    : 0;
            } else {
                return 0;
            }
        };

        const castMapping = [
            "BMW",
            "CH",
            "GTI",
            "GT",
            "GTB",
            "TB/TS",
            "GTO",
            "GTS",
            "GTC",
            "XC",
            "EC",
            "RS",
            "RSQ",
            "McLaren",
            "600LT",
        ];

        // Fonction qui permet de gérer la case et l'affichage en majuscule
        function formatQuery(query) {
            if (query.toLowerCase().startsWith("mclaren")) {
                const rest = query.slice("mclaren".length);
                return "McLaren" + capitalizeSpecial(rest);
            } else {
                return capitalizeSpecial(query);
            }
        }

        function capitalizeSpecial(word) {
            return word
                .split(" ") // Split the query into words
                .map((word) => {
                    const prefix = castMapping.find((prefix) =>
                        word.toUpperCase().startsWith(prefix),
                    );
                    if (prefix) {
                        return (
                            prefix +
                            word.slice(prefix.length).charAt(0).toUpperCase() +
                            word.slice(prefix.length + 1).toLowerCase()
                        );
                    } else {
                        return (
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        );
                    }
                })
                .join(" ");
        }

        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient,
            indexName: indexName + "_query_suggestions",
            getSearchParams({ state }) {
                return { hitsPerPage: state.query ? 5 : 10 };
            },
            transformSource({ source }) {
                return {
                    ...source,
                    sourceId: "querySuggestionsPlugin",
                    onSelect({ item, state }) {
                        if (page.url.startsWith("/marketplace")) {
                            setInstantSearchUiState({
                                query: item.query,
                            });
                        } else {
                            toMarketplace({ query: item.query });
                        }
                        state.query = formatQuery(item.query);
                    },
                    getItems(params) {
                        if (!params.state.query) {
                            return [];
                        }

                        return source.getItems(params);
                    },
                    templates: {
                        ...source.templates,
                        item(params) {
                            const { item, html, state } = params;
                            const formattedQuery = formatQuery(item.query);
                            // Highlight de la partie de la recherche qui match
                            const userInput = state.query.toLowerCase();
                            const matchStartIndex = formattedQuery
                                .toLowerCase()
                                .indexOf(userInput);
                            const matchEndIndex =
                                matchStartIndex + userInput.length;

                            const beforeMatch = formattedQuery.substring(
                                0,
                                matchStartIndex,
                            );
                            const match = formattedQuery.substring(
                                matchStartIndex,
                                matchEndIndex,
                            );
                            const afterMatch =
                                formattedQuery.substring(matchEndIndex);

                            return html` <span class="aa-ItemContentTitle">
                                ${beforeMatch}<mark>${match}</mark>${afterMatch}
                            </span>`;
                        },
                    },
                };
            },
        });

        this.autocompleteInstance = autocomplete({
            container: `#${identifiant}`,
            placeholder: "Rechercher",
            detachedMediaQuery: "none",
            initialState: { query: research ?? "" },
            onSubmit({ state }) {
                if (page.url.startsWith("/marketplace")) {
                    setInstantSearchUiState({ query: state.query });
                } else {
                    toMarketplace({ query: state.query });
                }
            },
            onReset() {
                if (page.url.startsWith("/marketplace")) {
                    setInstantSearchUiState({ query: "" });
                }
            },
            onStateChange({ prevState, state }) {
                if (prevState.query !== state.query) {
                    if (page.url.startsWith("/marketplace")) {
                        setInstantSearchUiState({ query: state.query });
                    }
                }
            },
            openOnFocus: true,
            plugins: [recentSearchesPlugin, querySuggestionsPlugin],
            getSources() {
                return [
                    {
                        sourceId: "brands",
                        getItems({ query }) {
                            return [
                                {
                                    label: "Porsche",
                                    img: porscheSvg,
                                },
                                { label: "Ferrari", img: ferrariSvg },
                                { label: "Audi", img: audiSvg },
                                {
                                    label: "Mercedes",
                                    img: mercedesSvg,
                                },
                                { label: "McLaren", img: mclarenSvg },
                                {
                                    label: "Lamborghini",
                                    img: lamborghiniSvg,
                                },
                            ].filter(({ label }) =>
                                label
                                    .toLowerCase()
                                    .includes(query.toLowerCase()),
                            );
                        },
                        templates: {
                            item({ item, html }) {
                                return html`<button
                                    class="aa-ItemLink"
                                    onclick=${() => updateAlgoliaState(item)}
                                >
                                    <img
                                        class="aa-Item--icon"
                                        src="${item.img}"
                                        alt="${item.label}"
                                    />
                                    ${item.label}
                                </button>`;
                            },
                        },
                    },
                ];
            },
            render({ elements, render, html, state }, root) {
                const { recentSearchesPlugin, querySuggestionsPlugin, brands } =
                    elements;

                if (!state.query) {
                    render(
                        html`<div class="aa-PanelLayout aa-Panel--scrollable">
                            <div class="aa-PanelSections">
                                ${displayRecentSearches() > 0
                                    ? html`<div>
                                          <div class="aa-SourceHeader">
                                              <span
                                                  class="aa-SourceHeaderTitle"
                                              >
                                                  ${t("recentSearches")}
                                              </span>
                                          </div>
                                          ${recentSearchesPlugin}
                                      </div>`
                                    : ""}
                                <div>
                                    <div class="aa-SourceHeader">
                                        <span class="aa-SourceHeaderTitle"
                                            >${t("iconicBrands")}</span
                                        >
                                    </div>
                                    <div class="aa-PanelSectionBrands">
                                        ${brands}
                                    </div>
                                </div>
                            </div>
                        </div>` as unknown as VNode,
                        root,
                    );
                } else {
                    render(
                        html`<div class="aa-PanelLayout aa-Panel--scrollable">
                            <div class="aa-PanelSections">
                                <div>
                                    <Fragment>
                                        ${querySuggestionsPlugin}
                                    </Fragment>
                                </div>
                            </div>
                        </div>` as unknown as VNode,
                        root,
                    );
                }
            },
        });
    },

    beforeUnmount() {
        this.autocompleteInstance?.destroy();
    },
};
</script>

<template>
    <div class="autocomplete" :id="identifiant"></div>
</template>

<style lang="scss">
.autocomplete {
    flex-grow: 1;
    border-radius: 4px;
    @include large-up {
        border: none;
        min-width: 50px;
        border-radius: 0;
    }

    &__header {
        display: none;
        border-radius: 4px;
        border: 1px solid #d8dcdc;
        min-width: 800px;
        @include large-up {
            display: block;
        }
        &--mobile {
            display: block;
            min-width: auto;
            width: 90%;
            margin: 0 auto;
        }
    }
}
.aa-Panel {
    z-index: 70;
    margin-top: -2px;
    padding: 0 1rem 1rem 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    border: 2px solid $grey-card;
}

.aa-PanelLayout {
    padding: 0;
}

.aa-SourceHeader {
    padding-top: 1rem;
    margin-top: 0;
}

.aa-SourceHeaderTitle {
    color: black;
    font-size: 1rem;

    @media (max-width: $breakpoint-l) {
        font-weight: 500;
    }
}
.aa-List {
    display: flex;
    margin-top: 0.75rem;

    @media (max-width: $breakpoint-l) {
        display: block;
    }

    .aa-Item {
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid rgba(217, 220, 220, 0.5);
        margin-right: 0.5rem;
        padding: 0.75rem 0.5rem;
        @media (max-width: $breakpoint-l) {
            border: none;
            padding: 0.25rem 0 0.5rem 0;
            min-height: auto;
        }

        &--icon {
            margin-right: 0.5rem;
            max-height: 22px;

            @media (max-width: $breakpoint-l) {
                display: none;
            }
        }
        .aa-ItemContent {
            .aa-ItemIcon {
                display: none;
            }
        }
        .aa-ItemActions {
            @media (max-width: $breakpoint-l) {
                display: none;
            }
        }
    }
}

.aa-ItemActionButton {
    padding: 0;
    svg {
        margin: 0 0 0 0.5rem;
    }
}

.aa-PanelSectionBrands {
    > .aa-Source > .aa-List > .aa-Item {
        padding: 0;
        > .aa-ItemLink {
            cursor: pointer;
            padding: 0.5rem;
            @media (max-width: $breakpoint-l) {
                padding: 0.25rem 0 0.5rem 0;
            }
        }
    }
}

/*Style spécifique pour l'affichage des suggestions de recherche
on cible l'id car la classe est la même pour les recherches récentes*/
[id^="autocomplete-"][id$="-querySuggestionsPlugin-list"] {
    display: flex;
    flex-direction: column;
    .aa-Item {
        border: none;
        > .aa-ItemContentTitle {
            color: rgba(0, 0, 0, 0.2);
            font-weight: 400;
            > mark {
                font-weight: 700;
                color: black;
                background-color: transparent;
            }
        }
    }
}

.aa-Label svg {
    color: black;
}

.aa-Form {
    border: none;

    &:focus-within {
        box-shadow: none;
    }
}
</style>
