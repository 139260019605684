<script setup lang="ts">
import { computed, PropType } from "vue";

const emit = defineEmits(["update:checked"]);

type VarianteCheckbox = "classique" | "switch";

const props = defineProps({
    checked: {
        type: [Array, Boolean, Number],
        default: false,
    },
    value: {
        type: [String, Number, Boolean],
        default: null,
    },
    type: {
        type: String,
        default: "checkbox",
    },
    variante: {
        type: String as PropType<VarianteCheckbox>,
        default: "classique",
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit("update:checked", val);
    },
});
</script>

<template>
    <label :class="{ switch: variante == 'switch' }">
        <input
            v-model="proxyChecked"
            :type="type"
            :value="value"
            class="checkbox"
            :class="{ rounded: type == 'radio' }"
        />
        <span v-show="variante === 'switch'" class="slider round"></span>
    </label>
</template>

<style lang="scss" scoped>
.checkbox {
    margin-right: 5px;
    appearance: none;
    border: 1px solid $light-bg;
    background: white;
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    transition:
        background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out;

    &:checked {
        background: $primary url("../../../../images/icons/check.svg") no-repeat
            center center / 1rem;
        border-color: $primary;
    }

    &.rounded {
        border-radius: 50%;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #99b1b5;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #003c46;
}

input:focus + .slider {
    box-shadow: 0 0 1px #003c46;
}

input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 32px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
