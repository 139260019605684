<script setup lang="ts">
import { Link, usePage, router } from "@inertiajs/vue3";
import Menu from "./Menu.vue";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import algoliasearch from "algoliasearch/lite";
import Autocomplete from "../Components/Algolia/Autocomplete.vue";
import { history } from "instantsearch.js/es/lib/routers";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import Button from "@/Components/Buttons/Button.vue";

const menuOpen = ref(false);

const page = usePage();
const isHomepage = ref<boolean | null>(null);

watch(
    page,
    () => {
        isHomepage.value = page.component === "Home";
    },
    { immediate: true },
);

const { t } = useI18n();

const getPageStyle = () => {
    return page.component === "Vehicle/Index" ||
        page.component === "Vehicle/LandingModelSpecification" ||
        page.component === "AboutUs"
        ? "white"
        : "black";
};
const style = ref(getPageStyle());

const displaySearchBar = ref(false);

router.on("success", () => {
    style.value = getPageStyle();
});

router.on("start", () => {
    menuOpen.value = false;
    document.body.classList.remove("app-menu-open");
});

const toggleMenu = () => {
    menuOpen.value = !menuOpen.value;
    document.body.classList.toggle("app-menu-open");
};

const displayAutocomplete = () => {
    return (
        page.component === "Vehicle/LandingMake" ||
        page.component === "Vehicle/LandingModel" ||
        page.component === "Vehicle/Show" ||
        page.component === "SavedSearch"
    );
};

const handleClick = () => {
    router.visit(route("vehicles.index"));
};

const searchClient = algoliasearch(
    page.props.algolia.appId,
    page.props.algolia.searchKey,
);

const routing = {
    router: history(),
    stateMapping: singleIndex(page.props.algolia.indexName),
};
</script>

<template>
    <header id="app-header" :class="`header header--${style}`">
        <div class="header__container container" :data-page="page.component">
            <Link :href="route('vehicles.index')" class="header__link">
                <img
                    src="../../images/joinsteer-logo-green.svg"
                    class="header__link-img"
                    width="78"
                    height="39"
                    alt="Joinsteer Logo"
                />
            </Link>

            <div v-if="displayAutocomplete()">
                <ais-instant-search
                    :search-client="searchClient"
                    :index-name="$page.props.algolia.indexName"
                    :routing="routing"
                >
                    <Autocomplete
                        identifiant="search-autocomplete"
                        :search-client="searchClient"
                        :index-name="$page.props.algolia.indexName"
                        :class="'autocomplete__header'"
                    />
                </ais-instant-search>
            </div>

            <div class="header__action">
                <Button
                    v-if="isHomepage"
                    icon-size="small"
                    left-icon="SingleRightArrow"
                    @click="handleClick"
                    variante="secondary"
                    class="header__marketPlace"
                >
                    {{ t("home.animation.header.button") }}
                </Button>
                <button
                    v-if="
                        !displaySearchBar &&
                        page.component !== 'Vehicle/Index' &&
                        page.component !== 'Home'
                    "
                    class="header__searchbutton"
                    @click="displaySearchBar = !displaySearchBar"
                >
                    <img
                        src="../../images/icons/search-green.svg"
                        class="header__button-img"
                        width="24"
                        height="24"
                        alt=""
                    />
                </button>

                <button
                    class="header__button"
                    @click="toggleMenu"
                    :aria-label="t('toggleMenu')"
                >
                    <img
                        src="../../images/icons/menu-green.svg"
                        class="header__button-img"
                        width="24"
                        height="24"
                        alt=""
                    />
                    <img
                        v-if="page.props.auth?.user?.avatar_url"
                        :src="page.props.auth?.user?.avatar_url"
                        class="header__button-img-user profile"
                        width="24"
                        height="24"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../images/icons/user-green.svg"
                        class="header__button-img-user"
                        width="24"
                        height="24"
                        alt=""
                    />
                </button>
            </div>
            <Menu :open="menuOpen" @close-menu="toggleMenu" />
        </div>
    </header>
    <div v-if="displayAutocomplete() && displaySearchBar">
        <ais-instant-search
            :search-client="searchClient"
            :index-name="$page.props.algolia.indexName"
            :routing="routing"
        >
            <Autocomplete
                identifiant="search-autocomplete-mobile"
                :search-client="searchClient"
                :index-name="$page.props.algolia.indexName"
                :class="
                    'autocomplete__header' +
                    (displaySearchBar ? ' autocomplete__header--mobile' : '')
                "
            />
        </ais-instant-search>
    </div>
</template>

<style lang="scss" scoped>
.header {
    position: sticky;
    z-index: 100;
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 0.3s;
    $this: &;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;

        &[data-page="Vehicle/Index"],
        &[data-page="Vehicle/LandingModelSpecification"] {
            padding-bottom: 2rem;
        }
    }

    &__action {
        display: flex;
        gap: 0.5rem;
    }

    &__button {
        background: none;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        padding: 0.5rem;
    }
    &__button-img-user {
        display: none;
    }

    &__marketPlace {
        align-items: center;
        padding: 0.5rem 0.875rem;
        border-color: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        font-size: 0.875rem;
        text-shadow: 0 0 10px white;
    }

    &__searchbutton {
        display: block;
        appearance: none;
        border: none;
        background-color: transparent;
        @include medium-up {
            display: none;
        }
    }
    .homepage & {
        &#{$this}--white {
            background-color: rgba(255, 255, 255, 0);
            > .header__action
                > .header__button
                > .header__button-img-user.profile,
            .header__link,
            .header__action {
                filter: invert(1) brightness(1.5);
            }
        }
    }

    @include medium-down {
        &__marketPlace {
            font-size: 0.875rem;
        }
    }

    @media (min-width: 50rem) {
        &--white {
            background-color: rgba(255, 255, 255, 0);
            > .header__action
                > .header__button
                > .header__button-img-user.profile,
            .header__link,
            .header__action {
                filter: invert(1) brightness(1.5);
            }
        }
        &__button {
            border: 1px solid rgba(0, 0, 0, 0.4);
            transition: border 300ms;

            &:hover {
                border-color: #{$dark-font};
            }
        }
        &__button-img-user {
            display: block;
            border-radius: 3rem;
        }
    }
}
</style>

<style lang="scss">
@media screen and (max-width: $breakpoint-m) {
    body.app-menu-open {
        overflow: hidden;
    }
}

.header {
    .autocomplete__header {
        .aa-SubmitIcon {
            fill: $dark-bg;
        }
        > .aa-Autocomplete > form {
            background-color: #f2f5f6;
        }
    }
}
</style>
